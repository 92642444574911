html, body, #root, .App {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background: #000000;
}

.App {
  color: #ffffff;
  font-family: 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
}

.navbar {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navbar .icons img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar li {
  margin: 0 1rem;
}

.navbar a {
  color: #ff9caa;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar a:hover {
  color: #ffffff;
}

html {
  scroll-behavior: smooth;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding-top: 60px;
}

.white-text {
  color: #ffffff;
  font-size: 2rem;
  font-weight: bold;
}

.pink-text {
  color: #ff9caa;
  font-size: 4rem;
  font-weight: bold;
}

.typed-text {
  color: #ff9caa;
  font-size: 2rem;
  font-weight: bold;
}

.cursor {
  display: inline-block;
  width: 3px;
  background-color: #ff9caa;
  margin-left: 5px;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.content-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
  padding: 100px 50px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.content-container.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.headshot-container {
  flex: 0 0 auto;
}

.headshot {
  width: 300px;
  height: auto;
  border: 4px solid #ff9caa;
  border-radius: 8px;
  box-shadow: 0 0 20px #ff9caa;
}

.about-section {
  flex: 1;
  max-width: 600px;
}

.about-title {
  color: #ff9caa;
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 0;
}

.about-section p {
  color: #ffffff;
  font-size: 1.27rem;
  line-height: 1.9;
  margin: 0;
  font-weight: 400;
}

.skills-section, .projects-section {
  padding: 100px 50px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.skills-section.fade-in, .projects-section.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.skills-title, .projects-title {
  color: #ff9caa;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 50px;
}

.skills-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  justify-items: center;
}

.skill-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.skill-item img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.skill-item p {
  margin: 0;
  font-size: 1.2rem;
}

.projects-tiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.project-tile {
  border: 2px solid #ff9caa;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.project-tile img {
  width: 100%;
  height: auto;
  display: block;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-tile:hover .project-overlay {
  opacity: 1;
}

.project-title {
  color: #ff9caa;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.project-tech {
  color: #ffffff;
  font-size: 1rem;
  text-align: center;
  padding: 0 10px;
}